<template>
  <div class="footer">
    <div class="footer_box">
      <div class="footer_top">
        <div class="footer_ul">
          <div class="footer_list" v-for="(item,index) in listData">
              <div class="footer_li">{{item.title}}</div>
              <div class="footer_li" v-for="(list,index1) in item.list" @click="goBtn(list.url)">
                <div v-if="list.types == 0">{{list.name}}</div>
                <el-popover placement="top" width="260" trigger="hover" v-else>
                  <div slot="reference">{{list.name}}</div>
                  <div>
                    <img :src="$baseUrl + list.image" alt="" srcset="">
                  </div>
                </el-popover>
              </div>
            </div>
        </div>
        <div class="footer_logo">
          <img src="../assets/images/logo1.png" alt="" srcset="">
        </div>
      </div>
      <div class="footer_tips">
        {{contentData.beian}} &nbsp;&nbsp; | &nbsp;&nbsp; 版权所有©轻鹿面试
        <div class="footer_tips_right">
          <img src="../assets/images/output.png" alt="" srcset="">&nbsp;
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004885">川公网安备 51019002004885号</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import vmson from "./emptyVue"
  export default {
    data() {
      return{
        listData:[],
        contentData:'',
        flag:false
      };
    },
    mounted(){
      vmson.$on('header_num',(options)=>{
        // this.list_index = options.index
        if(options.total == options.index && options.idx == 0){
          this.flag = true
        }else{
          this.flag = false
        }
        if(options.idx != 0){
          this.flag = true
        }
      })
      this.getLink()
      this.getConfig()
    },
    methods: {
      getLink(){
        this.request.post("/api/pc/getLink", {}).then(res => {
          console.log(res,222222)
          this.listData = res.data
        })
      },
      getConfig(){
        this.request.post("/api/pc/getConfig", {}).then(res => {
          console.log(res,222222)
          this.contentData = res.data
        })
      },
      goBtn(url){
        window.location.href = url
      }
    },
    
  };
  </script>

<style scoped lang="scss">
  .footer{
    width: 100%;
    padding: 30px 0 20px;
    background: #fff;
    margin-top: 30px;
    overflow: hidden;
    // position: fixed;
    // left: 0;
    // bottom: 0;
    .footer_box{
      width: 1200px;
      margin: 0 auto;
      .footer_top{
        width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footer_ul{
          display: flex;
          justify-content: flex-start;
          padding-bottom: 20px;
          .footer_list{
            padding-right: 120px;
            .footer_li{
              padding: 5px 0;
              cursor: pointer;
              font-size: 14px;
              color: #333;
              text-align: left;
            }
            .footer_li:nth-child(1){
              padding-bottom: 15px;
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
        .footer_logo{
          width: 204px;
		      height: 45px;
          image{
            width: 100%;
		        height: 100%;
          }
        }
      }
      .footer_tips{
        font-size: 13px;
        color: #333;
        padding: 20px 0 0;
        border-top: 1px solid #F0F0F0;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          margin-left: 10px;
        }
        a{
          display:inline-block;
          text-decoration:none;
          color: #333;
        }
        .footer_tips_right{
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
  
</style>