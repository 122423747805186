<template>
  <div class="header_box " :class="list_index > 1?'header_box_b':''" v-if="list_index != 20">
    <div class="header">
      <div class="header_left">
        <div class="header_logo" @click="$router.push('/')">
          <img src="../assets/images/logo.png" mode="" v-if="list_index <= 1">
          <img src="../assets/images/logo1.png" mode="" v-else>
        </div>
        <div class="header_ul " :class="list_index > 1?'header_ul_c':''">
          <div class="header_li" :class="idx==index && list_index > 1?'header_li_hover_b':'' || idx==index ?'header_li_hover':''" @click="goSwitch(index)" v-for="(item,index) in listData" :key="index">{{item.name}}</div>
          <!-- <div class="header_li" @click="goInterview">我的面试稿</div>
          <div class="header_li">精品服务</div>
          <div class="header_li">课堂学习</div> -->
        </div>
      </div>
      <div class="header_right">
        <div class="header_right_phone" >
          
          <el-popover placement="bottom" width="300" trigger="hover">
            <div class="popover_box">
              <div class="popover_box_title">轻鹿面试小程序</div>
              <img src="../assets/images/header_code.jpg" mode="" class="popover_box_top">
            </div>
            <div class="header_right_phone_box" :class="list_index <= 1?'header_right_phone_c':''" slot="reference">
              <img src="../assets/images/phone2.png" mode="" v-if="list_index > 1">
              <img src="../assets/images/phone.png" mode="" v-if="list_index <= 1">
              <div >小程序</div>
            </div>
          </el-popover>
        </div>

        <div class="header_right_box">
          <el-popover placement="bottom" width="150" trigger="click" v-if="userInfos">
            <div class="popover_box" v-if="userInfos">
              <img :src="$baseUrl + userInfos.avatar" mode="" class="popover_box_img">
              <div class="popover_box_name">
                <div>{{userInfos.nickname}}</div>
                <img src="../assets/images/header_jf.png" mode="">
              </div>
              <div calss="popover_box_time" style="font-size: 12px;color: #999;padding-bottom: 25px;" v-if="userInfos.is_vip == 1">有效期至 {{userInfos.vip_endtime}}</div>
              <div calss="popover_box_time" style="font-size: 12px;color: #999;padding-bottom: 25px;" v-else>{{userInfos.vip_endtime}}</div>
            </div>
            <div class="popover_li" @click="$router.push('/personal?index=' + 1)">
              <div class="popover_li_left">
                <img src="../assets/images/header_li1.png" alt="" srcset="">
                <div>账号设置</div>
              </div>
              <img src="../assets/images/header_go.png" alt="" srcset="">
            </div>
            <div class="popover_li" @click="$router.push('/personal?index=' + 2)">
              <div class="popover_li_left">
                <img src="../assets/images/header_li2.png" alt="" srcset="">
                <div>我的订单</div>
              </div>
              <img src="../assets/images/header_go.png" alt="" srcset="">
            </div>
            <div class="popover_li" @click="$router.push('/member')">
              <div class="popover_li_left">
                <img src="../assets/images/header_li3.png" alt="" srcset="">
                <div>轻鹿会员</div>
              </div>
              <img src="../assets/images/header_go.png" alt="" srcset="">
            </div>
            <div class="popover_li" @click="$router.push('/personal?index=' + 3)">
              <div class="popover_li_left">
                <img src="../assets/images/header_li4.png" alt="" srcset="">
                <div>优惠券</div>
              </div>
              <img src="../assets/images/header_go.png" alt="" srcset="">
            </div>
            <div class="popover_li" @click="$router.push('/personal?index=' + 4)">
              <div class="popover_li_left">
                <img src="../assets/images/header_li5.png" alt="" srcset="">
                <div>我的消息</div>
              </div>
              <img src="../assets/images/header_go.png" alt="" srcset="">
            </div>
            <div class="popover_li" @click="$router.push('/personal?index=' + 5)">
              <div class="popover_li_left">
                <img src="../assets/images/header_li6.png" alt="" srcset="">
                <div>我的预约</div>
              </div>
              <img src="../assets/images/header_go.png" alt="" srcset="">
            </div>
            <div class="popover_li" @click="closeBtn">
              <div class="popover_li_left">
                <img src="../assets/images/header_li7.png" alt="" srcset="">
                <div>退出登录</div>
              </div>
              <img src="../assets/images/header_go.png" alt="" srcset="">
            </div>
            <img :src="$baseUrl + userInfo.avatar" slot="reference" class="header_right_img">
          </el-popover>
          <div class="header_right_btn" :class="list_index > 1?'header_right_btn_l':''" @click="goLogin" v-if="!userInfo">登录</div>
          <div class="header_right_btn header_right_btn_b" :class="list_index > 1?'header_right_btn_h':''" @click="goLogin" v-if="!userInfo">注册</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import vmson from "./emptyVue"
  export default {
    data() {
      return{
        list_index:1,
        idx:0,
        isActive:false,
        flag:true,
        userInfo:'',
        userInfos:'',
        listData:[{
          name:'首页'
        },{
          name:'我的面试稿'
        },{
          name:'精品服务'
        },{
          name:'课堂学习'
        }]
      };
    },
    mounted(){
      vmson.$on('header_num',(options)=>{
        this.list_index = options.index
        this.idx = options.idx
        if(sessionStorage.getItem('userinfo')){
          this.userInfo = JSON.parse(sessionStorage.getItem('userinfo'))
          this.userInfoStr()
        }
        let geturl = window.location.href 
        let getqyinfo = geturl.split('?')[1]
        let getqys = new URLSearchParams('?'+getqyinfo)
        let getqycode = getqys.get('code')
        if(getqycode && !sessionStorage.getItem('userinfo')){
          this.app_auth_login(getqycode)
        }
      })
    },
    methods: {
      app_auth_login(getqycode){
        this.request.post("/api/user/app_auth_login", {
          code : getqycode,
          event : 'pc'
        }).then(res => {
            console.log(res)
            if(res.code == 1){
              sessionStorage.setItem('userinfo', JSON.stringify(res.data));
              this.userInfo = res.data
              this.userInfos = res.data
              this.$message({
                message: res.msg,
                type: 'success'
              });
            }else{
              // this.$message.error(res.msg);
            }
        })
      },
      userInfoStr(){
        this.request.post("/api/user/userInfo", {
        }).then(res => {
            if(res.code == 1){
              this.userInfo = res.data
              this.userInfos = res.data
            }
        })
      },
      goSwitch(index){
        this.idx = index
        if(index == 0){
          if(this.$router.currentRoute.path != "/"){
            this.list_index = 1
          }
          this.$router.push('/')
        }else if(index == 1){
          this.$router.push('/interview')
          this.list_index = 2
        }else if(index == 2){
          this.$router.push('/service')
          this.list_index = 2
        }else if(index == 3){
          this.$router.push('/classroom')
          this.list_index = 4
        }
      },
      goLogin(){
        this.$router.push('./login')
      },
      closeBtn(){
        sessionStorage.setItem('userinfo', '');
        this.$message({
          message: '退出成功！',
          type: 'success'
        });
        setTimeout(() => {
          this.$router.push('/login')
        }, 1000);
      }
    },
    watch:{
      str(){
        console.log(123)
      }
    }
  };
  </script>

<style scoped lang="scss">
  .header_right{
    .header_right_phone{
      
      margin-right: 15px;
      color: #333;
      cursor: pointer;
      /deep/.header_right_phone_box{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-right: 1px solid #626467;
        padding-right: 20px;
        color: #333;
      }
      /deep/.header_right_phone_c{
        color: #fff;
        border-right: 1px solid #fff;
      }
      img{
        width: 16px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
  
    
  .popover_box{
    text-align: center;
    .popover_box_title{
      font-size: 18px;
      color: #333;
      padding-bottom: 10px;
    }
    .popover_box_top{
      width: 258px;
      height: 258px;
    }
    .popover_box_img{
      width: 54px;
      height: 54px;
      border-radius: 50%;
    }
    .popover_box_name{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      padding: 16px 0 10px;
      color: #333;
      img{
        width: 18px;
        height: 18px;
      }
    }
    .popover_box_times{
      font-size: 20px;
      color: #999;
      padding-bottom: 30px;
    }
  }
  .popover_li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #333;
    padding: 7px 0;
    cursor: pointer;
    img{
      width: 6px;
      height: 12px;
      margin-right: 8px;
    }
    .popover_li_left{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: bold;
      img{
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }
    
  }
  .header_box{
    width: 100vw;
    position: fixed;
		top: 0;
		left: 0;
		z-index: 9;
  }
 
	.header{
		width: 1200px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 auto;
		padding: 35px 0;

	}
	.header_left{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.header_logo{
		width: 204px;
		height: 45px;
    cursor: pointer;
	}
	.header_logo image{
		width: 204px;
		height: 45px;
	}
	.header_ul{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 50px;
		color: #fff;
    .header_li{
      padding: 6px 15px;
      margin: 0 15px;
      font-size: 18px;
      cursor: pointer;
    }
    .header_li_hover{
      color: #1884C2;
      background: #fff;
      border-radius: 4px;
    }
    .header_li_hover_b{
      color: #1884C2;
      border-radius: 4px;
      background: rgb(231,242,249);
    }
	}
	.header_right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
   
    .header_right_box{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .header_right_img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
    .header_right_btn{
      width: 70px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border: 1px solid #fff;
      margin-left: 15px;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
    }
    .header_right_btn_b{
      background: #fff;
      color: #1884C2;
    }
    .header_right_btn_h{
      background: #1884C2;
      color: #fff;
    }
    .header_right_btn_l{
      border: 1px solid #F0F0F0;
      color: #626467;
    }
  }
  .header_box_b{
    background: #fff;
    box-shadow: 0px 6px 17px 0px rgba(13,60,76,0.1);
  }
  .header_ul_c{
    color: #626467;
  }

</style>