<template>
	<div class="fullPage">
    <!-- <Header/> -->

		<!-- <div class="fullPageContainer" ref="fullPageContainer" @mouseup="mouseWheelHandle" @mousewheel="mouseWheelHandle" @DOMMouseScroll="mouseWheelHandle"> -->
			<div class="section">
				<div class="section_top_h1">{{listData.title}}</div>
        <div class="section_top_h2">{{listData.sub_title}}</div>
        <!-- <div class="section_top_btn" @click.stop="goMf" @mouseup.stop @click="$router.push(`/interview`)" >免费生成面试稿</div> -->
        <div class="section_top_btn" @click.stop="goMf" @mouseup.stop @click="$router.push(`/interview`)" >免费生成面试稿</div>
        <CommonVideo :src="$baseUrl + listData.file" class="section_top_video" :videoData="videoData" style="width: 900px!important;height: 506.25px!important;" v-if="listData.file_type == 1"/>
        <img :src="$baseUrl + listData.file" alt="" srcset="" v-if="listData.file_type == 0" style="width: 900px!important;height: 506.25px!important;">
			</div>
			<div class="section section_bg" v-for="(item,index) in listData1" :key="index">
        <div class="section_li" v-if="index%2==0">
          <div class="section_left">
            <div class="section_left_h1">{{item.title}}</div>
            <div class="section_left_h2" >
              <div v-html="item.desc"></div>
            </div>
            <div class="section_left_btn" @mouseup.stop @click="goLink(item.jump_url,item.jump_type,item.id)">{{item.btn_name}}</div>
          </div>
          <CommonVideo :src="$baseUrl + item.file" class="section_top_video" :videoData="videoData" style="width: 660px!important;height: 371.25px!important;" v-if="item.file_type == 1"/>
          <img :src="$baseUrl + item.file" alt="" srcset="" v-if="item.file_type == 0" style="width: 660px!important;height: 600px!important;">
        </div>
        <div class="section_li" v-else>
          <CommonVideo :src="$baseUrl + item.file" class="section_top_video" :videoData="videoData" style="width: 660px!important;height: 371.25px!important;" v-if="item.file_type == 1"/>
          <img :src="$baseUrl + item.file" alt="" srcset="" v-if="item.file_type == 0" style="width: 660px!important;height: 600px!important;">
          <div class="section_left">
            <div class="section_left_h1">{{item.title}}</div>
            <div class="section_left_h2" >
              <div v-html="item.desc"></div>
            </div>
            <div class="section_left_btn" @mouseup.stop @click="goLink(item.jump_url,item.jump_type,item.id)">{{item.btn_name}}</div>
          </div>
        </div>
      </div>
		<!-- </div> -->
    <Footer/>
	</div>  
</template>

<script>
  import CommonVideo from '@/components/video.vue'
  import vmson from "./emptyVue"
  import Footer from '@/components/footer.vue'
  export default {
    components: {
      CommonVideo,
      Footer
    },
    data() {
      return{
        fullpage:{
          current: 1,
          isScrolling: false,
          deltaY: 0,
        },
        listData:[],
        listData1:[],
        // videoUrl:require('../assets/index_sp.mp4'),
        videoData:{},
        heightFlag:true
      };
    },
    mounted() {
      vmson.$emit('header_num', { index: 0,idx:0})
      this.index()
      window.addEventListener('scroll', this.handleScroll)
    },
    destroyed(){
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      handleScroll() {
        this.screenHeight = document.documentElement.scrollTop || document.body.scrollTop;
        if(this.screenHeight > 950){
          vmson.$emit('header_num',{index:2,idx:0})
        }else{
          vmson.$emit('header_num',{index:1,idx:0})
        }
      },
      index(){
        this.request.post("/api/pc/index", {
          type:0
        }).then(res => {
            console.log(res)
            
            if(res.code == 1){
              this.listData = res.data[0]
              let str = res.data
              this.listData1 = str.slice(1)
            }
        })
      },
      goLink(url,type,id){
        if(type == 0){
          window.location.href = url
        }else{
          this.$router.push('./tempDetails?id=' + id)
        }
      },
      cf(){
        console.log(11111111,vmson)
      },
      goMf(){
        console.log(22222)
      },
      next() {
        let len = this.listData1.length + 1;
        let _this = this
        if (this.fullpage.current + 1 <= len) {
          this.fullpage.current += 1;
          this.move(this.fullpage.current);
          console.log(this.fullpage.current)
          vmson.$emit('header_num',{index:this.fullpage.current,idx:0,total:this.listData1.length + 1})
        }
      },
      pre() {
        if (this.fullpage.current - 1 > 0) {
          this.fullpage.current -= 1;
          this.move(this.fullpage.current);
          console.log(this.fullpage.current)
          vmson.$emit('header_num',{index:this.fullpage.current,idx:0,total:this.listData1.length + 1})
        }
      },
      move(index) {
        this.fullpage.isScrolling = true;
        this.directToMove(index);
        setTimeout(() => {
          this.fullpage.isScrolling = false;
        }, 1010);
      },
      directToMove(index) {
        let height = this.$refs["fullPage"].clientHeight;
        let scrollPage = this.$refs["fullPageContainer"];
        let scrollHeight;
        scrollHeight = -(index - 1) * height + "px";
        scrollPage.style.transform = `translateY(${scrollHeight})`;
        this.fullpage.current = index;
      },
      mouseWheelHandle(event) {
        let evt = event || window.event;
        if (evt.stopPropagation) {
          evt.stopPropagation();
        } else {
          evt.returnValue = false;
        }
        if (this.fullpage.isScrolling) {
          return false;
        }
        let e = event.originalEvent || event;
        this.fullpage.deltaY = e.deltaY || e.detail;
        if (this.fullpage.deltaY > 0) {
          this.next();
        } else if (this.fullpage.deltaY < 0) {
          this.pre();
        }
      },
    },
  };
  </script>

<style scoped lang="scss">
  .fullPage {
    padding: 0;
    margin: 0;
    width: 100%;
    height: calc(100vh);
    // overflow: hidden;
    // background-color: rgb(189, 211, 186);
    // .fullPageContainer {
    //   width: 100vw;
    //   height: calc(100vh);
    //   transition: all linear 0.5s;
    // }
  }
  
	.section {
    width: 100%;
    height: calc(100vh);
    background: url(../assets/images/index_bg1.png) no-repeat;
    background-position: center center;
    .section_top_h1{
      font-size: 80px;
      font-weight: bold;
      color: #fff;
      padding-top: 11vh;
    }
    .section_top_h2{
      font-size: 34px;
      font-weight: 400;
      color: #fff;
      padding-top: 2.5vh;
    }
    .section_top_btn{
      width: 268px;
      height: 68px;
      text-align: center;
      line-height: 68px;
      color: #333;
      background: #FCCA00;
      border-radius: 10px;
      box-shadow: 0px 2px 32px 0px rgba(219,163,7,0.22);
      font-size: 24px;
      margin: 3vh auto 4vh;
      cursor: pointer;
    }
    .section_top_video{
      margin: 0 auto;
      border-radius: 10px;
      overflow: hidden;
    }
	}
	.section_bg{
    background: url(../assets/images/index_bg2.png);
    
	}
	.section_bg:nth-child(odd){
		background: url(../assets/images/index_bg3.png);
	}
  .section_li{
    width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 260px;
    .section_left{
      text-align: left;
      .section_left_h1{
        font-size: 36px;
        color: #333;
        font-weight: bold;
        padding-bottom: 30px;
      }
      .section_left_h2{
        font-size: 20px;
        color: #666;
        font-weight: 400;
        padding-bottom: 10px;
      }
      .section_left_btn{
        width: 120px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #333;
        background: #FCCA00;
        border-radius: 10px;
        box-shadow: 0px 2px 32px 0px rgba(219,163,7,0.22);
        font-size: 16px;
        margin-top: 90px;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
  .footer{
    margin-top: 0px;
  }
</style>