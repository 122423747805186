import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router'
import router from './router/router'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
// import VueVideoPlayer from 'vue-video-player';
//  import 'video.js/dist/video-js.css';
//  Vue.use(VueVideoPlayer);
import request from "./utils/request";
Vue.prototype.request = request

import store from './store/store'
Vue.prototype.$store = store
Vue.prototype.$baseUrl = 'https://qlcs.xdgm21.com'
// Vue.prototype.$baseUrl = 'https://xydj.xdgm21.com'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter);
// if(sessionStorage.getItem('token')){
//   console.log(sessionStorage.getItem('token'))
//   request.defaults.headers['token'] = JSON.parse(sessionStorage.getItem('token'));
// }
// axios.defaults.headers.post['Content-Type'] = 'application/json';


new Vue({
  render: h => h(App),
  router:router
}).$mount('#app')
