import VueRouter  from "vue-router";
import Vue  from "vue";
Vue.use(VueRouter)
const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("../components/home.vue"),
        meta: {
            title: '首页'
        }
    },
    {
        path: "/interview",
        name: "interview",
        component: () => import("../components/interview.vue"),
        meta: {
            title: '面试稿'
        }
    },
    {
        path: "/draft",
        name: "draft",
        component: () => import("../components/draft.vue"),
        meta: {
            title: '我的面试稿'
        }
    },
    {
        path: "/applyDraft",
        name: "applyDraft",
        component: () => import("../components/applyDraft.vue"),
        meta: {
            title: '申请成为模板'
        }
    },
    {
        path: "/service",
        name: "service",
        component: () => import("../components/service.vue"),
        meta: {
            title: '精品服务'
        }
    },
    {
        path: "/serviceDetails",
        name: "serviceDetails",
        component: () => import("../components/serviceDetails.vue"),
        meta: {
            title: '预约定制'
        }
    },
    {
        path: "/classroom",
        name: "classroom",
        component: () => import("../components/classroom.vue"),
        meta: {
            title: '课堂学习'
        }
    },
    {
        path: "/classroomDetails",
        name: "classroomDetails",
        component: () => import("../components/classroomDetails.vue"),
        meta: {
            title: '课程详情'
        }
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../components/login.vue"),
        meta: {
            title: '登录'
        }
    },
    {
        path: "/personal",
        name: "personal",
        component: () => import("../components/personal.vue"),
        meta: {
            title: '个人信息'
        }
    },
    {
        path: "/editorial",
        name: "editorial",
        component: () => import("../components/editorial.vue"),
        meta: {
            title: '修改编辑稿'
        }
    },
    {
        path: "/editorialSumbit",
        name: "editorialSumbit",
        component: () => import("../components/editorialSumbit.vue"),
        meta: {
            title: '修改编辑稿'
        }
    },
    {
        path: "/appointment",
        name: "appointment",
        component: () => import("../components/appointment.vue"),
        meta: {
            title: '预约面试'
        }
    },
    {
        path: "/appointmentDetails",
        name: "appointmentDetails",
        component: () => import("../components/appointmentDetails.vue"),
        meta: {
            title: '活动详情'
        }
    },
    {
        path: "/member",
        name: "member",
        component: () => import("../components/member.vue"),
        meta: {
            title: '开通会员'
        }
    },
    {
        path: "/memberPay",
        name: "memberPay",
        component: () => import("../components/memberPay.vue"),
        meta: {
            title: '开通会员支付'
        }
    },
    {
        path: "/tempDetails",
        name: "tempDetails",
        component: () => import("../components/tempDetails.vue"),
        meta: {
            title: '模板详情'
        }
    },
    {
        path: "/singlePage",
        name: "singlePage",
        component: () => import("../components/singlePage.vue"),
        meta: {
            title: '轻鹿面试'
        }
    }
];

const router = new VueRouter({
    routes
});

export default router;