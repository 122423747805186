<template>
  <div>
    <div class="kf_box" @click="dialogListVisible = true">
      <img src="../assets/images/kf_img.png" alt="" srcset="">
      <div>在线客服</div>
    </div>
    <el-dialog :visible.sync="dialogListVisible" width="450px" :show-close="false">
      <div class="dialog_box">
        <div class="dialog_box_top">
          <div class="dialog_box_title">微信扫码咨询在线客服</div>
          <img src="../assets/images/wx_code.png" alt="" srcset="">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return{
        dialogListVisible:false,
       
      };
    },
    mounted(){
      
    },
    methods: {
      
    },
  };
  </script>

<style scoped lang="scss">
  
  .kf_box{
    width: 80px;
    border-radius: 4px;
    background: #fff;
    
    text-align: center;
    color: #999;
    font-size: 12px;
    padding: 20px 0;
    position: fixed;
    right: 20px;
    bottom: 100px;
    cursor: pointer;
    image{
      width: 18px;
      height: 18px;
    }
  }
  :deep(){
    .el-dialog{
      background: none;
      .el-dialog__header{
        display: none!important;
      }
      .el-dialog__body{
        padding: 0;
      }
    }
  }
  .dialog_box{
    padding: 30px;
    background: #fff;
    z-index: 99999;
    .dialog_box_top{
      .dialog_box_title{
        font-size: 20px;
        color: #333;
        font-weight: bold;
        padding-bottom: 20px;
      }
      image{
        width: 350px;
        height: 350px;
      }
    }
  }
</style>